import React, { Component } from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import './PorfolioPage.css';
import { FiArrowRight } from 'react-icons/fi';

export default class PortfolioPage extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      items: props.items.edges,
    };
  }
  render() {
    if (this.state.items.length > 0) {
      return (
        <div className="items">
          {this.state.items.map(({ node }) => {
            let viewMore = null;
            if (node.isShowDetail === true) {
              viewMore = (
                <div className="item-more">
                  <Link to="" className="read-more aboutLink ">
                    Read More
                    <FiArrowRight />
                  </Link>
                </div>
              );
            }
            let tagsView = null;
            tagsView = (
              <div className="item-tags">
                {node.tags.map((tag) => {
                  return (
                    <div key={tag.index} className="item-tag">
                      {tag}
                    </div>
                  );
                })}
              </div>
            );
            return (
              <div className="portfolio-item" key={node.id}>
                <Img
                  fluid={node.img.fluid}
                  className="item-img"
                  alt={node.name}
                />
                <h4 className="item-title">{node.name}</h4>
                {tagsView}
                <div className="item-desc">{node.desc.desc}</div>
                {viewMore}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="raw">
            <div className="col">
              <h1>Still Working</h1>
            </div>
          </div>
        </div>
      );
    }
  }
}
