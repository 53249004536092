import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/layout';
import PortfolioPage from '../components/PortfolioPage';

const portfolio = ({ data }) => (
  <Layout>
    <SEO title="Portfolio" />
    <PortfolioPage items={data.portfolio} />
  </Layout>
);
export default portfolio;
export const query = graphql`
  {
    portfolio: allContentfulPortfolio(sort: { fields: time, order: DESC }) {
      edges {
        node {
          id
          tags
          time
          name
          img {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          desc {
            desc
          }
          isShowDetail
        }
      }
    }
  }
`;
